var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.away,
      expression: "away"
    }],
    staticClass: "relative"
  }, [_c('button', {
    staticClass: "text-gray-900 flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
    on: {
      "click": _vm.toggle
    }
  }, [_c('span', {
    staticClass: "cursor-pointer flex items-center i18n-locale"
  }, [_c('img', {
    staticClass: "h-4 w-5",
    attrs: {
      "src": _vm.i18n_locale_img,
      "alt": _vm.$i18n.locale
    }
  }), _c('span', {
    staticClass: "hidden sm:block ml-2"
  }, [_vm._v(_vm._s(_vm.getCurrentLocaleData.lang))]), _c('i', {
    staticClass: "material-icons"
  }, [!_vm.open ? _c('span', [_vm._v("arrow_drop_down")]) : _c('span', [_vm._v("arrow_drop_up")])])])]), _vm.open ? _c('div', {
    staticClass: "absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-40",
    attrs: {
      "x-transition:enter": "transition ease-out duration-100",
      "x-transition:enter-start": "transform opacity-0 scale-95",
      "x-transition:enter-end": "transform opacity-100 scale-100",
      "x-transition:leave": "transition ease-in duration-75",
      "x-transition:leave-start": "transform opacity-100 scale-100",
      "x-transition:leave-end": "transform opacity-0 scale-95"
    }
  }, [_c('div', {
    staticClass: "px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800 vs-dropdown--menu"
  }, [_c('li', {
    staticClass: "text-gray-900 hover:text-green-500 vs-dropdown--item",
    on: {
      "click": function click($event) {
        return _vm.updateLocale('en');
      }
    }
  }, [_vm._m(0)]), _c('li', {
    staticClass: "text-gray-900 hover:text-green-500 vs-dropdown--item",
    on: {
      "click": function click($event) {
        return _vm.updateLocale('fr');
      }
    }
  }, [_vm._m(1)])]), _c('div', {
    staticClass: "vs-dropdown--menu--after"
  })]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "vs-dropdown--item-link"
  }, [_c('img', {
    staticClass: "h-4 w-5 mr-1",
    attrs: {
      "src": require("@/assets/flags/en.svg"),
      "alt": "en"
    }
  }), _vm._v("  English")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "vs-dropdown--item-link"
  }, [_c('img', {
    staticClass: "h-4 w-5 mr-1",
    attrs: {
      "src": require("@/assets/flags/fr.svg"),
      "alt": "fr"
    }
  }), _vm._v("  Français")]);

}]

export { render, staticRenderFns }