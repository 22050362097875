<template>
  <div v-on-clickaway="away" class="relative">
    <button
      @click="toggle"
      class="text-gray-900 flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
    >
      <span class="cursor-pointer flex items-center i18n-locale">
        <img
          class="h-4 w-5 blob green"
          :src="
            country_img ||
            'https://img.icons8.com/material-outlined/2x/image.png'
          "
          :alt="country_data.code"
        />
        <span class="hidden sm:block ml-2"
          >{{ country_data.name }}
          <sup v-if="idWharhouse" class="font-bold uppercase"
            >({{ idWharhouse.currency }})</sup
          ></span
        >
        <i class="material-icons"
          ><span v-if="!open">arrow_drop_down</span
          ><span v-else>arrow_drop_up</span></i
        >
      </span>
    </button>
    <div
      v-if="open"
      x-transition:enter="transition ease-out duration-100"
      x-transition:enter-start="transform opacity-0 scale-95"
      x-transition:enter-end="transform opacity-100 scale-100"
      x-transition:leave="transition ease-in duration-75"
      x-transition:leave-start="transform opacity-100 scale-100"
      x-transition:leave-end="transform opacity-0 scale-95"
      class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-60"
    >
      <div
        class="px-1 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800 vs-dropdown--menu"
      >
        <li
          v-for="(item, index) in warehousesAll"
          :key="index"
          @click="changeWrahouse(item.code)"
          class="text-gray-900 hover:text-green-500 vs-dropdown--item"
        >
          <a class="vs-dropdown--item-link ware-item"
            ><img
              class="h-4 w-5 mr-1"
              :src="$f.getCountryCode(item.code)"
              :alt="item.code"
            />
            &nbsp;{{ item.name }}
            <div class="checkboxWrapper">
              <i class="material-icons" style="font-size: 20px"
                ><span v-if="country_data.code == item.code"
                  >fiber_manual_record</span
                ><span v-else>panorama_fish_eye</span></i
              >
            </div></a
          >
        </li>
      </div>
      <div class="vs-dropdown--menu--after"></div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import _ from "lodash";
export default {
  mixins: [clickaway],
  props: {
    user: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      open: false,
      options: this.$countries,
      country_img: "",
      warehousesAll: [],
      country_data: {},
      currency: "",
    };
  },
  async mounted() {
    this.warehousesAll = await this.warehouses;

    this.getCurrency();
    this.country_img = await this.country_locale_img;
    this.country_data = await this.getCurrentLocaleData;
  },
  computed: {
    async warehouses() {
      return this.$store.getters["wharhouse/warehouses"]({
        user: _.pick(this.user, ["countries"]),
      });
    },
    async warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.user, ["countries"]),
      });
    },
    async country_locale_img() {
      return this.$f.getCountryCode(await this.warhouseSelected);
    },
    async getCurrentLocaleData() {
      return {
        name: this.$f.getCountryNameByCode(
          this.options,
          await this.warhouseSelected
        ),
        code: await this.warhouseSelected,
      };
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      this.getCurrency();
    },
  },
  methods: {
    async getCurrency() {
      const country = await this.warhouseSelected;
      // await this.$store.dispatch('wharhouse/getCurrency',{country:await this.warhouseSelected}).then((res) => {
      //       this.currency=res;
      //   })

      // if(country) this.currency=this.warehousesAll.find(o => o.code === country).currency;
    },
    async getWarehouses() {
      await this.$store
        .dispatch("wharhouse/getWarehouses", {
          user: _.pick(this.user, ["countries"]),
        })
        .then((res) => {
          this.warehousesAll = res;
        });
    },
    async changeWrahouse(value) {
      this.$store.dispatch("wharhouse/changeWrahouse", { warhouse: value });

      this.country_img = await this.country_locale_img;
      this.country_data = await this.getCurrentLocaleData;
      localStorage.setItem("countryW", value);
      this.open = false;
    },
    async toggle() {
      this.open = !this.open;

      this.country_data = await this.getCurrentLocaleData;
      await this.getWarehouses();

      await this.getCurrency();
    },
    away() {
      this.open = false;
    },
  },
};
</script>
<style scoped>
.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}
@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
.vs-dropdown--item {
  text-align: left;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  z-index: 100;
  border-radius: 5px;
  width: calc(100% - 6px);
  margin: 0 3px;
  list-style: none;
  font-weight: 400 !important;
  font-size: 0.9375em;
}
.vs-dropdown--item-link {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding: 5px 1px;
  cursor: pointer;
}
.vs-dropdown--menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.vs-dropdown--menu--after {
  top: 0;
  right: 30px;
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  background: #fff;
  -webkit-transform: rotate(45deg) translate(-7px);
  transform: rotate(45deg) translate(-7px);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.checkboxWrapper {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.ware-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
