<template>
  <div style="display:none">
    <a class="text-white block py-1 px-3 mr-4 relative cursor-pointer" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
       <i class="material-icons">notifications</i><span class="dot-count vs-avatar--count badgeNumber bg-red-500">4</span>
    </a>
    <div ref="popoverDropdownRef" class="bg-white text-base z-50 float-left mt-2 list-none text-left rounded-md shadow-lg min-w-48" style="width:20rem;" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
    <div class="py-2"> 
      <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
          <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80" alt="avatar">
          <p class="text-gray-600 text-sm mx-2">
              <span class="font-bold" href="#">Slick Net</span> Create new order . 45m
          </p>
        </a>
        <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
          <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80" alt="avatar">
          <p class="text-gray-600 text-sm mx-2">
              <span class="font-bold" href="#">Slick Net</span> Change status order to confirmed . 45m
          </p>
        </a>
        <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100">
          <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80" alt="avatar">
          <p class="text-gray-600 text-sm mx-2">
              <span class="font-bold" href="#">Slick Net</span> Create new order . 45m
          </p>
        </a>
        <a href="#" class="flex items-center px-4 py-3 hover:bg-gray-100">
          <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=398&amp;q=80" alt="avatar">
          <p class="text-gray-600 text-sm mx-2">
              <span class="font-bold" href="#">Abigail Bennett</span> Create new order . 3h
          </p>
        </a>
      </div> 
      <a href="#" class="block bg-gray-800 text-white text-center font-bold py-2">See all notifications</a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
<style scoped>
.badgeNumber {
   position: absolute;
    z-index: 100;
    width: auto;
    height: auto;
    top: -3px;
    right: 4px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: .625em;
    color: #fff;
    pointer-events: none;
}
</style>
