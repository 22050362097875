var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "btnDropdownRef",
    on: {
      "mouseover": function mouseover($event) {
        return _vm.onOver($event);
      },
      "mouseleave": _vm.onLeave
    }
  }, [_c('a', {
    staticClass: "text-gray-600 block",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('div', {
    staticClass: "items-center flex profile"
  }, [_vm._m(0), _c('span', {
    staticClass: "cursor-pointer text-green-600 ml-2 title"
  }, [_vm._v(_vm._s(_vm.user.fullName) + " "), _c('span', {
    staticClass: "details"
  }, [_vm._v(_vm._s(_vm.user.type) + " ")])])])]), _c('div', {
    ref: "popoverDropdownRef",
    staticClass: "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48",
    class: {
      hidden: !_vm.dropdownPopoverShow,
      block: _vm.dropdownPopoverShow
    }
  }, [_c('router-link', {
    staticClass: "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800",
    attrs: {
      "to": "/profil"
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("person_outline")]), _c('span', {
    staticClass: "mx-4 font-medium"
  }, [_vm._v(_vm._s(_vm.$t('profil')))])])]), _c('div', {
    staticClass: "h-0 my-2 border border-solid border-gray-200"
  }), _c('a', {
    staticClass: "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800",
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("input")]), _c('span', {
    staticClass: "mx-4 font-medium"
  }, [_vm._v(_vm._s(_vm.$t('logout')))])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-10 h-10 text-sm text-white bg-green-600 inline-flex items-center justify-center rounded-full"
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("person")])]);

}]

export { render, staticRenderFns }