<template>
  <!-- Navbar -->
  <nav
    id="topBar"
    class="absolute top-0 z-10 left-0 w-full bg-white md:flex-row md:flex-no-wrap md:justify-start flex items-center p-2"
  >
    <div class="relative flex flex-0 items-center w-full h-12 px-4 md:px-4 z-49">
      <!-- Brand -->
      <button
        @click="actionSidebar"
        class="focus:outline-none mat-focus-indicator mat-icon-button mat-button-base text-green-600"
      >
        <span class="mat-button-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fit=""
            height="100%"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path></svg></span
        ><span
          matripple=""
          class="mat-ripple mat-button-ripple mat-button-ripple-round"
        ></span
        ><span class="mat-button-focus-overlay"></span>
      </button>

      <router-link
        v-if="getPermission('dashboard')"
        to="/"
        class="text-green-600 ml-5"
      >
        <svg
          class="inline-block h-6 w-6 fill-current mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M3.889 3h6.222a.9.9 0 0 1 .889.91v8.18a.9.9 0 0 1-.889.91H3.89A.9.9 0 0 1 3 12.09V3.91A.9.9 0 0 1 3.889 3zM3.889 15h6.222c.491 0 .889.384.889.857v4.286c0 .473-.398.857-.889.857H3.89C3.398 21 3 20.616 3 20.143v-4.286c0-.473.398-.857.889-.857zM13.889 11h6.222a.9.9 0 0 1 .889.91v8.18a.9.9 0 0 1-.889.91H13.89a.9.9 0 0 1-.889-.91v-8.18a.9.9 0 0 1 .889-.91zM13.889 3h6.222c.491 0 .889.384.889.857v4.286c0 .473-.398.857-.889.857H13.89C13.398 9 13 8.616 13 8.143V3.857c0-.473.398-.857.889-.857z"
          ></path>
        </svg>
        {{ $t("dashboard") }}
      </router-link>
      <!-- Form -->
      <!-- <form
        class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
      >
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
             <i class="material-icons">search</i>
          </span>
          <input
            type="text"
            placeholder="Search here..."
            class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10"
          />
        </div>
      </form>-->
      <div class="flex items-center pl-2 ml-auto space-x-2">
        <ul
          class="flex-col md:flex-row list-none items-center md:flex notification"
        ></ul>
        <!-- User -->
        <ul class="flex-col md:flex-row list-none items-center md:flex">
          <Language />

          <!-- <Country
            v-if="all['multi-warehouses'].isActive"
            :user="user"
            :idWharhouse="idWharhouse"
          /> -->

           <Country
            :user="user"
            :idWharhouse="idWharhouse"
          /> 
          <user-dropdown :user="user" :idWharhouse="idWharhouse" />
        </ul>
      </div>
    </div>
  </nav>

  <!-- End Navbar -->
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
import Language from "@//components/Dropdowns/Language.vue";
import Country from "@//components/Dropdowns/Country.vue";
import { mapState } from "vuex";
export default {
  components: {
    UserDropdown,
    NotificationDropdown,
    Language,
    Country,
  },
  props: {
    user: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      topBar: "bg-white",
    };
  },
  computed: {
    ...mapState({
      all: (state) => state.features.all,
    }),
  },
  methods: {
    actionSidebar() {
      this.$store.dispatch("actionSidebar");
    },
    getPermission(name) {
      if (this.user) {
        const permissions = this.user.role.permissions;
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];
          //
          if (model == name) {
            if (
              access.create ||
              access.read ||
              access.update ||
              access.delete
            ) {
              if (this.user.type == "CancelledOrder" && name == "shippings")
                return false;
              else return true;
            } else {
              //
              return false;
            }
          }
        }
      }
    },
  },
};
</script>
<style>
#topBar {
  background: white none repeat scroll 0% 0%;
  color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
}
.z-9 {
  z-index: 9;
}
.profile .title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 65%;
}
.profile .details {
  font-size: 12px;
  opacity: 0.4;
  display: block;
  clear: both;
  text-transform: uppercase;
}
</style>
