var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('a', {
    ref: "btnDropdownRef",
    staticClass: "text-white block py-1 px-3 mr-4 relative cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.toggleDropdown($event);
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("notifications")]), _c('span', {
    staticClass: "dot-count vs-avatar--count badgeNumber bg-red-500"
  }, [_vm._v("4")])]), _c('div', {
    ref: "popoverDropdownRef",
    staticClass: "bg-white text-base z-50 float-left mt-2 list-none text-left rounded-md shadow-lg min-w-48",
    class: {
      hidden: !_vm.dropdownPopoverShow,
      block: _vm.dropdownPopoverShow
    },
    staticStyle: {
      "width": "20rem"
    }
  }, [_vm._m(0), _c('a', {
    staticClass: "block bg-gray-800 text-white text-center font-bold py-2",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("See all notifications")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-2"
  }, [_c('a', {
    staticClass: "flex items-center px-4 py-3 border-b hover:bg-gray-100",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "h-8 w-8 rounded-full object-cover mx-1",
    attrs: {
      "src": "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      "alt": "avatar"
    }
  }), _c('p', {
    staticClass: "text-gray-600 text-sm mx-2"
  }, [_c('span', {
    staticClass: "font-bold",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Slick Net")]), _vm._v(" Create new order . 45m ")])]), _c('a', {
    staticClass: "flex items-center px-4 py-3 border-b hover:bg-gray-100",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "h-8 w-8 rounded-full object-cover mx-1",
    attrs: {
      "src": "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      "alt": "avatar"
    }
  }), _c('p', {
    staticClass: "text-gray-600 text-sm mx-2"
  }, [_c('span', {
    staticClass: "font-bold",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Slick Net")]), _vm._v(" Change status order to confirmed . 45m ")])]), _c('a', {
    staticClass: "flex items-center px-4 py-3 border-b hover:bg-gray-100",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "h-8 w-8 rounded-full object-cover mx-1",
    attrs: {
      "src": "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      "alt": "avatar"
    }
  }), _c('p', {
    staticClass: "text-gray-600 text-sm mx-2"
  }, [_c('span', {
    staticClass: "font-bold",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Slick Net")]), _vm._v(" Create new order . 45m ")])]), _c('a', {
    staticClass: "flex items-center px-4 py-3 hover:bg-gray-100",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "h-8 w-8 rounded-full object-cover mx-1",
    attrs: {
      "src": "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80",
      "alt": "avatar"
    }
  }), _c('p', {
    staticClass: "text-gray-600 text-sm mx-2"
  }, [_c('span', {
    staticClass: "font-bold",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Abigail Bennett")]), _vm._v(" Create new order . 3h ")])])]);

}]

export { render, staticRenderFns }